<template>
  <v-container class="fill-height">
    <v-row justify="center">
      <v-col cols="12">
        <!-- Form Toggle Switch -->
        <v-row justify="center" align="center" class="mb-4">
          <v-col cols="auto">
            <v-switch v-model="isRegister" label="Регистрация" inset color="primary" :false-value="false"
              :true-value="true"></v-switch>
          </v-col>
        </v-row>

        <!-- Login Form -->
        <v-slide-x-reverse-transition hide-on-leave mode="out-in">
          <v-form v-show="!isRegister" ref="loginForm" v-model="loginValid" @submit.prevent="submitLoginForm">
            <v-card class="mb-4">
              <v-card-title>Вход</v-card-title>
              <v-card-text>
                <v-alert v-if="loginError" type="error">
                  {{ loginError }}
                </v-alert>
                <v-text-field label="Имейл" v-model="loginEmail" :rules="[rules.required, rules.email]" required
                  autocomplete="email" @keydown.enter="validateLoginForm"></v-text-field>
                <v-text-field label="Парола" v-model="loginPassword" :rules="[rules.required]" type="password" required
                  autocomplete="current-password" @keydown.enter="validateLoginForm"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col cols="12" sm="6" class="text-center">
                    <v-btn color="primary" type="submit" :disabled="!loginValid">
                      Вход
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="6" class="text-center">
                    <v-btn class="ml-auto" color="primary" @click="forgottenPasswordDialog = true">
                      Забравена Парола
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-slide-x-reverse-transition>

        <!-- Register Form -->
        <v-slide-x-transition hide-on-leave mode="out-in">
          <v-form v-show="isRegister" ref="registerForm" v-model="registerValid" @submit.prevent="submitRegisterForm">
            <v-card>
              <v-card-title>Регистрация</v-card-title>
              <v-card-text>
                <v-alert v-if="registerError" type="error">
                  {{ registerError }}
                </v-alert>
                <v-alert v-if="registerSuccess" type="success">
                  {{ registerSuccess }}
                </v-alert>
                <v-text-field label="Имейл" v-model="registerEmail" :rules="[rules.required, rules.email]" required
                  autocomplete="email" @keydown.enter="validateRegisterForm"></v-text-field>
                <v-text-field label="Парола" v-model="registerPassword" :rules="[rules.required]" type="password"
                  required autocomplete="new-password" @keydown.enter="validateRegisterForm"></v-text-field>
                <v-text-field label="Потвърдете паролата" v-model="registerConfirmPassword"
                  :rules="[rules.required, isPasswordMatch]" type="password" required autocomplete="new-password"
                  @keydown.enter="validateRegisterForm"></v-text-field>
                <v-text-field label="Име" v-model="registerFirstName" :rules="[rules.required]" required
                  autocomplete="given-name" @keydown.enter="validateRegisterForm"></v-text-field>
                <v-text-field label="Фамилия" v-model="registerLastName" :rules="[rules.required]" required
                  autocomplete="family-name" @keydown.enter="validateRegisterForm"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col cols="12" class="text-center">
                    <v-btn color="primary" type="submit" :disabled="!registerValid">
                      Регистрация
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-slide-x-transition>
        <v-btn block color="white" class="my-3" link
          href="https://accounts.google.com/o/oauth2/v2/auth?client_id=274616705277-sfqu4a46q6gm4os6s4skgpqj6ecm1ttu.apps.googleusercontent.com&response_type=code&scope=openid%20email%20profile&redirect_uri=https%3A%2F%2Fsledotursach.com%2Fgoogle_auth">
          <img style="height: 18px; margin-right: 8px;" src="https://developers.google.com/identity/images/g-logo.png"
            alt="Google icon" />
          <span>Google</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog transition="dialog-bottom-transition" v-model="forgottenPasswordDialog" fullscreen>
      <v-card>
        <v-card-title>Забравена Парола</v-card-title>
        <div v-if="awaitingForgottenPasswordEmail" class="d-flex justify-center align-center">
          <v-progress-circular :size="150" :width="15" color="primary" indeterminate></v-progress-circular>
        </div>
        <v-form v-else ref="forgottenPasswordForm" v-model="forgottenPasswordValid"
          @submit.prevent="submitforgottenPasswordForm">
          <v-card class="mb-4">
            <v-card-text>
              <div class="my-2">
                <v-alert v-if="forgottenPassowrdError" type="error">
                  {{ forgottenPassowrdError }}
                </v-alert>
                <v-alert v-else-if="forgottenPasswordSuccess" type="success">
                  {{ forgottenPasswordSuccess }}
                </v-alert>
              </div>
              <v-text-field label="Имейл" v-model="forgottenPasswordEmail" :rules="[rules.required, rules.email]"
                required autocomplete="email" @input="forgottenPasswordSuccess = ''"
                @keydown.enter="validateForgottenPasswordForm"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" class="mx-auto" type="submit" :disabled="forgottenPasswordSendButtonDisabled">
                Изпрати
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
        <template v-slot:actions>
          <v-btn class="ms-auto" text="Затвори" @click="forgottenPasswordDialog = false"></v-btn>
        </template>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { useAuthenticationApi } from '/src/api/authentication';

export default {
  name: 'LoginRegister',
  data() {

    const { login, register, sendPasswordRecoveryEmail } = useAuthenticationApi();

    return {
      isRegister: false,
      forgottenPasswordDialog: false,
      loginEmail: '',
      loginPassword: '',
      registerEmail: '',
      registerPassword: '',
      registerConfirmPassword: '',
      registerFirstName: '',
      registerLastName: '',
      forgottenPasswordEmail: '',
      loginValid: false,
      registerValid: false,
      forgottenPasswordValid: false,
      loginError: '',
      registerError: '',
      registerSuccess: '',
      forgottenPassowrdError: '',
      forgottenPasswordSuccess: '',
      loginApiCall: login,
      registerApiCall: register,
      sendPasswordRecoveryMailApiCall: sendPasswordRecoveryEmail,
      awaitingForgottenPasswordEmail: false,
      rules: {
        required: value => !!value || 'Полето е задължително',
        email: value => /.+@.+\..+/.test(value) || 'Моля въведете валиден имейл',
      },
    };
  },
  methods: {
    async handleLogin() {
      let loginRes = await this.loginApiCall(this.loginEmail, this.loginPassword);
      if (false == loginRes) {
        this.loginError = "Грешен имейл или парола"
      }
      else {
        this.loginError = "";
      }
    },
    async handleRegister() {
      this.registerError = '';
      this.registerSuccess = '';
      const [registerRes, registerStatusCode] = await this.registerApiCall(this.registerEmail, this.registerPassword, this.registerFirstName, this.registerLastName);
      if (false == registerRes) {
        if (409 == registerStatusCode) {
          this.registerError = "Имейл адресът вече се използва";
        }
        else {
          this.registerError = "Възникна грешка. Моля опитайте отново"
        }
      }
      else {
        this.registerSuccess = "Успешна регистрация. Получихте линк за потвърждение на имейла си"
      }
    },
    async handleForgottenPassword() {
      this.forgottenPassowrdError = '';
      this.forgottenPasswordSuccess = '';
      this.awaitingForgottenPasswordEmail = true;
      const sentMailRes = await this.sendPasswordRecoveryMailApiCall(this.forgottenPasswordEmail);
      this.awaitingForgottenPasswordEmail = false;
      if (sentMailRes) {
        this.forgottenPasswordSuccess = `Имейлът беше изпратен на ${this.forgottenPasswordEmail}`;
        this.forgottenPassowrdError = '';
      }
      else {
        this.forgottenPasswordSuccess = '';
        this.forgottenPassowrdError = 'Проблем при изпращането на имейла. Моля опитайте отново по-късно';
      }
    },
    submitLoginForm() {
      if (this.loginValid) {
        this.handleLogin();
      }
    },
    submitRegisterForm() {
      if (this.registerValid) {
        this.handleRegister();
      }
    },
    submitforgottenPasswordForm() {
      if (this.forgottenPasswordValid) {
        this.handleForgottenPassword();
      }
    },
    validateLoginForm() {
      this.$refs.loginForm.validate();
    },
    validateRegisterForm() {
      this.$refs.registerForm.validate();
    },
    validateForgottenPasswordForm() {
      this.$refs.forgottenPasswordForm.validate();
    },
    isPasswordMatch(value) {
      return value === this.registerPassword || 'Паролите трябва да съвпадат.';
    },
  },
  computed: {
    forgottenPasswordSendButtonDisabled() {
      return !this.forgottenPasswordValid || this.forgottenPasswordSuccess != '' || this.forgottenPassowrdError != ''
    }
  }
};
</script>

<style scoped>
.card {
  margin-bottom: 20px;
}

.card-title {
  font-size: 1.5rem;
}

button {
  margin-top: 10px;
}
</style>