// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/components/HomePage.vue';
import LoginRegister from '@/components/LoginRegister.vue';
import GoogleAuth from '@/components/GoogleAuth.vue';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: { title: 'Начало - Следотърсач' }
  },
  {
    path: '/login',
    name: 'LoginRegister',
    component: LoginRegister,
    meta: { title: 'Вход - Следотърсач' }
  },
  {
    path: '/google_auth',
    name: 'GoogleAuth',
    component: GoogleAuth,
    meta: { title: 'Вход с Google - Следотърсач' }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  const { title, description } = to.meta;
  const defaultTitle = 'Следотърсач';
  const defaultDescription = 'Следотърсач';

  document.title = title || defaultTitle

  const descriptionTag = document.querySelector('meta[name="description"]');
  if (descriptionTag) {
    descriptionTag.setAttribute('content', description || defaultDescription);
  } else {
    const meta = document.createElement('meta');
    meta.name = 'description';
    meta.content = description || defaultDescription;
    document.head.appendChild(meta);
  }
})


export default router;