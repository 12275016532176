<template>
  <v-alert v-if="googleAuthError" type="error">
    <p>{{ googleAuthError }}</p>
  </v-alert>
  <v-alert v-else-if="success" type="success">
    <p>Успешно влизане</p>
  </v-alert>
  <v-btn class="my-2" color="primary" to="/">Начална Страница</v-btn>
</template>

<script>
import { useAuthenticationApi } from '/src/api/authentication';

export default {
  name: 'GoogleAuth',
  data() {
    const { authenticateWithGoogle } = useAuthenticationApi();

    return {
      google_code: this.$route.query.code,
      googleAuthApiCall: authenticateWithGoogle,
      googleAuthError: "",
      success: false
    };
  },
  async mounted() {
    if (this.google_code) {
      const [res, resStatusCode] = await this.googleAuthApiCall(this.google_code);
      this.success = res;
      if (this.success)
      {
        this.googleAuthError = "";
      }
      else
      {
        if (resStatusCode == 409) {
          this.googleAuthError = "Имейл адресът вече се използва"  
        }
        else {
          this.googleAuthError = "Невалиден код"
        }
      }
    }
    else {
      this.googleAuthError = "Липсващ код"
    }

  }
};
</script>

<style scoped>
/* Add any styles you need */
</style>