import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi'

import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css'

const sledotursachTheme = {
  dark: false,
  colors: {
    primary: '#4CBB86',
    secondary: '#7D7E81',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
  },
};

export default createVuetify({
  theme: {
    defaultTheme: 'sledotursachTheme',
    themes: {
      sledotursachTheme,
    },
  },
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});