<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <h1 class="text-center">Welcome to Sledotursach Frontend!</h1>

        <v-row justify="center">
          <v-btn color="primary" class="ma-2" @click="fetchData">
            Fetch Data
          </v-btn>
          <v-btn color="primary" class="ma-2" @click="logout">
            Logout
          </v-btn>
        </v-row>

        <v-card v-if="data" class="mt-4">
          <v-card-title>
            API Data:
          </v-card-title>
          <v-card-text>
            <pre>{{ data }}</pre>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import { useAuthenticationApi } from '/src/api/authentication';

export default {
  name: 'HomePage',
  data() {
    const { logout } = useAuthenticationApi();

    return {
      data: null,
      logoutApiCall: logout
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get('/API/places/types');
        this.data = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    async logout() {
      let logoutResp = await this.logoutApiCall();
      console.log("logoutResp = ", logoutResp);
    },
    onDetect(detectedCodes) {
      console.log(detectedCodes);
    }
  }
};
</script>

<style scoped>
pre {
  text-align: left;
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
}
</style>