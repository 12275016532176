<template>
  <v-app>
    <app-main-menu v-show="!store.loadingData" />

    <v-main>
      <div v-if="store.loadingData" class="d-flex justify-center align-center" style="height: 60vh;">
        <v-progress-circular :size="150" :width="15" color="primary" indeterminate></v-progress-circular>
      </div>
      <v-container v-show="!store.loadingData">
        <v-slide-x-transition mode="out-in">
          <router-view></router-view>
        </v-slide-x-transition>
      </v-container>
    </v-main>
    <app-footer v-show="!store.loadingData" />
  </v-app>
</template>

<script>
import { useAuthenticationApi } from '/src/api/authentication';
import { useRoute } from 'vue-router';
import { store } from '/src/store'

import AppFooter from '/src/components/AppFooter.vue';
import AppMainMenu from '/src/components/AppMainMenu.vue';

export default {
  name: 'App',
  data() {
    const { authenticate, getUserData, user, userIsAuthenticated } = useAuthenticationApi();
    const route = useRoute();
    const noAuthComponents = ["Blogs", "HomePage", 'LoginRegister', 'GoogleAuth'];

    return {
      authApiCall: authenticate,
      getUserDataApiCall: getUserData,
      user,
      userIsAuthenticated,
      route,
      noAuthComponents,
      email_verified: true,
      store
    };
  },

  watch: {
    'route.name'(newRoute) {
      this.handleRoutingWithAuthentication(newRoute);
    },
    userIsAuthenticated() {
      this.handleRoutingWithAuthentication(this.route.name);
    }
  },

  async created() {
    if (this.route.name != 'GoogleAuth') {
      let authRes = await this.authApiCall();
  
      if (authRes) {
        if (false == await this.getUserDataApiCall()) {
          console.log("Can not fetch user data");
        }
      }
    }

    this.handleRoutingWithAuthentication(this.route.name);
  },

  methods: {
    handleRoutingWithAuthentication(newRoute) {
      if (newRoute == undefined || newRoute == null) {
        this.$router.push('/');
        return;
      }

      if (this.userIsAuthenticated) {
        if (newRoute == "LoginRegister") {
          this.$router.push('/');
        }
      }
      else {
        if (false == this.noAuthComponents.includes(newRoute)) {
          this.$router.push('/login');
        }
      }
    }
  },

  components: {
    AppFooter,
    AppMainMenu
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>