<template>
  <v-app-bar :elevation="drawer ? 0 : 8" app>
    <v-btn icon @click="drawer = !drawer;" slim>
      <v-icon color="secondary">mdi-menu</v-icon>
    </v-btn>

    <v-app-bar-title class="text-center">
      <router-link :to="userIsAuthenticated ? '/home' : '/'">
        <img v-if="useBigLogo" style="width: 240px; max-width: 50vw;" src="/media/logo/Logo_Sledotursach.svg" alt="Sledotursach Logo" />
        <img v-else style="width: 48px;" src="/media/logo/Logo_Sledotursach_Short.svg" alt="Sledotursach Logo" />
      </router-link>
    </v-app-bar-title>

    <div class="my-auto">
      <v-btn icon @click="scanQrCodeDialog = true">
        <v-icon color="secondary">mdi-qrcode-scan</v-icon>
      </v-btn>
      <v-btn v-if="user" icon @click="notificationsDialog = true">
        <v-badge v-if="newNotificationsCountText" color="#F44336" :content="newNotificationsCountText">
          <v-icon color="secondary">mdi-bell-outline</v-icon>
        </v-badge>
        <v-icon v-else color="secondary">mdi-bell-outline</v-icon>
      </v-btn>
    </div>
  </v-app-bar>

  <v-navigation-drawer v-model="drawer" temporary>
    <div v-if="user">
      <v-list-item prepend-icon="mdi-account" :title="user.first_name + ' ' + user.last_name"></v-list-item>
      <v-divider></v-divider>
    </div>

    <v-slide-x-transition hide-on-leave mode="out-in">
      <v-list v-if="menuState === MenuStates.MAIN" density="compact" nav>
        <v-list-item prepend-icon="mdi-view-dashboard" to="/" title="Начало"></v-list-item>
        <v-list-item prepend-icon="mdi-post-outline" to="/blogs" title="Блогове"></v-list-item>
        <v-divider></v-divider>
        <v-list-item v-if="!userIsAuthenticated" prepend-icon="mdi-login" to="/login" title="Вход"></v-list-item>
        <v-list-item v-if="userIsAuthenticated" prepend-icon="mdi-logout" title="Изход"
          @click="logoutMethod"></v-list-item>
        <v-list-item prepend-icon="mdi-folder" title="More Options" @click="menuState = MenuStates.USER"></v-list-item>
      </v-list>
    </v-slide-x-transition>


    <v-slide-x-reverse-transition hide-on-leave mode="out-in">
      <v-list v-if="menuState === MenuStates.USER" density="compact" nav>
        <v-list-item prepend-icon="mdi-arrow-left" @click="menuState = MenuStates.MAIN" title="Back"></v-list-item>
        <v-list-item prepend-icon="mdi-settings" title="Settings"></v-list-item>
        <v-list-item prepend-icon="mdi-information" title="About"></v-list-item>
      </v-list>
    </v-slide-x-reverse-transition>

  </v-navigation-drawer>

  <v-dialog transition="dialog-top-transition" fullscreen v-model="notificationsDialog" scrollable>
    <v-card>
      <v-toolbar class="text-center">
        <v-btn color="primary" icon="mdi-bell-outline" @click="notificationsDialog = false"></v-btn>
        <v-toolbar-title class="ms-auto">Нотификации</v-toolbar-title>
        <v-btn color="primary" icon="mdi-close" @click="notificationsDialog = false"></v-btn>
      </v-toolbar>

      <div class="py-3 px-2">
        <notification-item v-for="(notification, index) in notifications" :key="index"
          :notificationData="notification"></notification-item>
      </div>

    </v-card>
  </v-dialog>

  <v-dialog transition="dialog-bottom-transition" v-model="scanQrCodeDialog" width="auto">
    <div class="text-center pa-4">
      <v-card max-width="400">
        <v-container>
          <p class=my-2>Сканирайте QR кода на тага</p>
          <qrcode-reader @qr-scanned-correctly="scanQrCodeDialog = false"></qrcode-reader>
          <p class="text-caption my-2"><i>При проблеми с камерата можете да прикачите само снимка или да сканирате кода
              с приложението си по подразбиране</i></p>
        </v-container>
        <template v-slot:actions>
          <v-btn class="ms-auto" text="Затвори" @click="scanQrCodeDialog = false"></v-btn>
        </template>
      </v-card>
    </div>
  </v-dialog>

  <v-dialog transition="dialog-bottom-transition" v-model="verificationEmailDialog" width="auto" persistent>
    <div class="text-center pa-4">
      <v-card max-width="400" title="Верификация">
        <v-container>
          <v-alert v-if="!sentVerificationEmail" type="warning">
            <p>Имейлът ви не е верифициран. Моля използвайте линка, който сте получили.</p>
            <v-btn class="my-2" color="primary" @click="sendVerificationEmail">Изпрати отново</v-btn>
          </v-alert>
          <v-alert v-if="sentVerificationEmail" type="success">
            <p class=my-2>Изпратен е линк за верификация на посочения имейл</p>
            <p class="text-caption my-2"><i>Имейлът скоро ще пристигне.</i></p>
          </v-alert>
        </v-container>
        <template v-slot:actions>
          <v-btn class="ms-auto" text="Провери верификацията" @click="getUserDataApiCall"></v-btn>
        </template>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { useAuthenticationApi } from '/src/api/authentication';
import QrcodeReader from '/src/components/QrcodeReader.vue';
import NotificationItem from '/src/components/NotificationItem.vue';

export default {
  name: 'AppMainMenu',
  data() {
    const { userIsAuthenticated, user, logout, getUserData, sendVerificationEmail } = useAuthenticationApi();
    const MenuStates = {
      MAIN: 'main',
      USER: 'user',
    };

    const notifications = [{
      pet_name: "Том",
      is_opened: false,
      longitude: 23.363323,
      latitude: 42.707515,
      location: "Стефан Караджа, бул. „Владимир Вазов“ 31, 1510 София, България",
      is_location_accurate: false,
      notification_timestamp: "2024-10-12T17:10:58"
    }, {
      pet_name: "Ванко 1",
      is_opened: true,
      longitude: 23.363323,
      latitude: 42.707515,
      location: "Стефан Караджа, бул. „Владимир Вазов“ 31, 1510 София, България",
      is_location_accurate: true,
      notification_timestamp: "2024-10-12T17:10:58"
    }];

    return {
      userIsAuthenticated,
      user,
      logoutApiCall: logout,
      getUserDataApiCall: getUserData,
      sendVerificationEmailApiCall: sendVerificationEmail,
      drawer: null,
      menuState: MenuStates.MAIN,
      notificationsDialog: false,
      notifications,
      scanQrCodeDialog: false,
      MenuStates,
      verificationEmailDialog: this.user && !this.user.email_verified,
      sentVerificationEmail: false,
      useBigLogo: true
    };
  },
  methods: {
    async logoutMethod() {
      this.drawer = false;
      await this.logoutApiCall();
      this.$router.push('/login');
    },
    async sendVerificationEmail() {
      await this.sendVerificationEmailApiCall();
      this.sentVerificationEmail = true;
    },
    updateBigLogoUsage() {
      if (window.innerWidth >= 360) {
        this.useBigLogo = true;
      } else {
        this.useBigLogo = false;
      }
    }
  },
  components: {
    QrcodeReader,
    NotificationItem
  },
  computed: {
    newNotificationsCountText() {
      let cnt = 0;
      this.notifications.forEach((notification) => {
        if (!notification.is_opened) {
          cnt++;
        }
      });

      if (cnt == 0) {
        return undefined;
      }
      if (cnt < 10) {
        return `${cnt}`;
      }
      else {
        return "10+";
      }
    },
  },
  watch: {
    async notificationsDialog(notificationsDialogNewVal) {
      if (this.newNotificationsCountText && notificationsDialogNewVal != true) {
        this.notifications.forEach((val, index) => this.notifications[index].is_opened = true);
        //await NotificationViewedApi();
      }
    },
    user(newUserValue) {
      this.verificationEmailDialog = newUserValue && !newUserValue.email_verified
    }
  },
  created() {
    this.updateBigLogoUsage();
    window.addEventListener('resize', this.updateBigLogoUsage);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateBigLogoUsage);
  },
}
</script>

<style></style>