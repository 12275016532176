<template>
  <v-alert v-if="qrCodeError" type="error" class="my-2 mx-3">
    Невалиден QR Код. Моля уверете се, че снимката е ясна.
  </v-alert>
  <v-file-input ref="qrCodeReaderFileInput" hide-input="true" prepend-icon=""
    @change="handleFileChange">
  </v-file-input>
  <div class="text-center">
    <v-btn color="primary" rounded="lg" tonal size="x-large" @click="triggerFileInputSelection">
      Сканирай <v-icon end color="white" size="x-large">mdi-qrcode-scan</v-icon>
    </v-btn>
  </div>
  <img ref="qrCodeReaderImgPreview" v-show="false" />
</template>

<script>
import QrScanner from 'qr-scanner';

export default {
  name: "QrcodeReader",
  data() {
    return {
      qrCodeError: false
    }
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (!file) return;

      const imgElement = this.$refs.qrCodeReaderImgPreview;
      imgElement.src = URL.createObjectURL(file);
      imgElement.onload = () => {
        QrScanner.scanImage(imgElement)
          .then(url => {
            const tagId = url.split("https://sledotursach.com/tag?tag_id=")[1];

            if (tagId) {
              this.$router.push({ path: '/tag', query: { tag_id: tagId } });
              this.$emit('qr-scanned-correctly');
              this.qrCodeError = false;
            }
            else {
              this.qrCodeError = true;
            }
          })
          .catch(() => {
            this.qrCodeError = true;
          });
      };
    },
    triggerFileInputSelection() {
      this.$refs.qrCodeReaderFileInput.$el.querySelector('input[type="file"]').click();
    },
  },
};

</script>

<style scoped></style>