import { ref, readonly } from 'vue';
import axios from 'axios';

const user = ref(null);
const userIsAuthenticated = ref(false);
import { store } from '/src/store'

export function useAuthenticationApi() {
  const logout = async () => {
    let res = false;
    store.loadingData = true;
    user.value = null;
    userIsAuthenticated.value = false;
    try {
      await axios.post('/API/authentication/logout');
      res = true;
    }
    catch {
      res = false;
    }

    store.loadingData = false;
    return res;
  };

  const login = async (email, password) => {
    let res = false;
    store.loadingData = true;

    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    try {
      await axios.post('/API/authentication/login', formData);
      userIsAuthenticated.value = true;
      res = true;
    } catch (_) {
      userIsAuthenticated.value = false;
      res = false;
    }
    if (res) {
      await getUserData();
    }
    store.loadingData = false;
    return res;
  };

  const authenticateWithGoogle = async (code) => {
    let res = false;
    store.loadingData = true;
    let statusCode = 400;

    try {
      const encoded_code = encodeURIComponent(code);
      await axios.get(`/API/authentication/google/${encoded_code}`);
      userIsAuthenticated.value = true;
      statusCode = 200;
      res = true;
    } catch (err) {
      userIsAuthenticated.value = false;
      statusCode = err.status;
      res = false;
    }
    if (res) {
      await getUserData();
    }
    store.loadingData = false;
    return [res, statusCode];
  };

  const register = async (email, password, firstName, lastName) => {
    let res = false;
    store.loadingData = true;

    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    let statusCode = 400;

    try {
      await axios.post('/API/user/register', formData);
      res = true;
      statusCode = 200;
    } catch (err) {
      statusCode = err.status;
      res = false;
    }
    store.loadingData = false;
    return [res, statusCode];
  };

  const authenticate = async () => {
    let res = false;
    store.loadingData = true;
    try {
      await axios.post('/API/authentication/authenticate');
      userIsAuthenticated.value = true;
      res = true;
    } catch (_) {
      userIsAuthenticated.value = false;
      res = false;
    }
    store.loadingData = false;
    return res;
  };

  const getUserData = async () => {
    let res = false;
    store.loadingData = true;
    try {
      let userDataResp = await axios.get('/API/authentication/data');
      user.value = userDataResp.data;
      res = true;
    } catch (_) {
      res = false;
    }
    store.loadingData = false;
    return res;
  };

  const sendVerificationEmail = async () => {
    let res = false;
    try {
      await axios.post('/API/user/send_verification_email');
      res = true;
    } catch (_) {
      res = false;
    }
    return res;
  };

  const sendPasswordRecoveryEmail = async (email) => {
    let res = false;
    try {
      await axios.post(`/API/user/send_recovery_password/${email}`);
      res = true;
    } catch (_) {
      res = false;
    }
    return res;
  };

  return {
    logout,
    login,
    authenticateWithGoogle,
    register,
    authenticate,
    getUserData,
    sendVerificationEmail,
    sendPasswordRecoveryEmail,

    user: readonly(user),
    userIsAuthenticated: readonly(userIsAuthenticated)
  };
}