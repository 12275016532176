<template>
  <v-footer id="appFooter" app absolute="false">
    <v-row justify="center pt-2" class="bg-secondary color-light ma-0 rounded-lg">
      <v-btn v-for="link in links" :key="link" class="mx-2" rounded="xl" variant="text" cols="auto">
        {{ link }}
      </v-btn>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} — <strong>Следотърсач</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {

    return {
      links: [
        'Начало',
        'За нас',
        'Контакти',
      ],
    };
  }
};
</script>

<style scoped></style>