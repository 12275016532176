<template>
  <v-card class="mx-2 my-4 bg-primary rounded-xl" target="_blank" :href="googleMapsLink">
    <v-toolbar color="primary" class="text-center px-2">
      <v-toolbar-title class="ms-auto">{{ notificationData.pet_name }} е Сканиран</v-toolbar-title>
      <v-icon v-if="notificationData.is_opened" color="white">mdi-cellphone-marker</v-icon>
      <v-badge v-else color="#F44336" dot>
        <v-icon color="white">mdi-cellphone-marker</v-icon>
      </v-badge>
    </v-toolbar>
    <v-container class="my-1 py-0 text-center">
      <p class="my-3">{{ notificationData.location }}</p>
    </v-container>
    <v-card-actions>
      <v-chip small>{{ formattedDate }}</v-chip>
      <v-chip v-if="notificationData.is_location_accurate" small> Точна</v-chip>
      <v-chip v-else small> Приблизителна</v-chip>
    </v-card-actions>
  </v-card>
</template>

<script>
import { parseISO, format } from 'date-fns';
import { bg } from 'date-fns/locale';

export default {
  name: 'NotificationItem',
  props: {
    notificationData: {
      type: Object,
      required: true
    }
  },
  data() {
    const googleMapsLink = `https://www.google.com/maps?q=${this.notificationData.latitude},${this.notificationData.longitude}`;

    return {
      googleMapsLink
    };
  },
  computed: {
    formattedDate() {
      const date = parseISO(this.notificationData.notification_timestamp);
      return format(date, 'dd MMM, HH:mm', { locale: bg });
    },
  }
};
</script>

<style scoped>
/* Add any styles you need */
</style>